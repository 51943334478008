/**
 * This file was automatically generated with 'graphql-code-generator' and should not be edited.
 * To regenerate this file update some *.graphql file and run `yarn codegen`
 *
 * See settings in project file: 'codegen.ts'
 */

/* eslint-disable */

import * as Types from '../../../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CustomObjectPropertyDetailFragment = {
  __typename?: 'CustomObjectProperty';
  id: any;
  label: string;
  dataType: Types.CustomPropDataTypeChoices;
  objectName: Types.CustomPropsObjectNamesChoices;
  tableColumnId: string;
  description: string;
  archivedAt?: any | null;
};

export type CustomObjectPropertyDetailQueryVariables = Types.Exact<{
  customObjectPropertyDetailId: Types.Scalars['CustomObjectPropertyID']['input'];
}>;

export type CustomObjectPropertyDetailQuery = {
  __typename?: 'Query';
  object:
    | {
        __typename?: 'CustomObjectProperty';
        id: any;
        label: string;
        dataType: Types.CustomPropDataTypeChoices;
        objectName: Types.CustomPropsObjectNamesChoices;
        tableColumnId: string;
        description: string;
        archivedAt?: any | null;
      }
    | { __typename?: 'ObjectDoesNotExistResponse'; objectId?: string | null };
};

export const CustomObjectPropertyDetailFragmentDoc = gql`
  fragment CustomObjectPropertyDetail on CustomObjectProperty {
    id
    label
    dataType
    objectName
    tableColumnId
    description
    archivedAt
  }
`;
export const CustomObjectPropertyDetailDocument = gql`
  query CustomObjectPropertyDetail($customObjectPropertyDetailId: CustomObjectPropertyID!) {
    object: customObjectPropertyDetail(id: $customObjectPropertyDetailId) {
      ... on ObjectDoesNotExistResponse {
        objectId
      }
      ... on CustomObjectProperty {
        ...CustomObjectPropertyDetail
      }
    }
  }
  ${CustomObjectPropertyDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CustomObjectPropertyDetailGQL extends Apollo.Query<
  CustomObjectPropertyDetailQuery,
  CustomObjectPropertyDetailQueryVariables
> {
  override document = CustomObjectPropertyDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
